import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import Page from '../../components/Page';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

export default function BlogsList() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('all');
  const [blogStatus, setBlogStatus] = useState('all');
  const [orderBy, setOrderBY] = useState('DESC');
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  let [loading, setLoading] = useState(false);

  const getBlogsTableData = async () => {
    try {
      const URL = `${apiPath.get_blog_list}?category_id=${categoryId}&published=${blogStatus}&orderBy=${orderBy}`;
      const res = await helper.get(URL, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setRecords(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headCell = [
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'category', label: 'Category', disableSorting: true },
    { id: 'likes', label: 'Likes', disableSorting: true },
    { id: 'sub_category', label: 'Sub Category', disableSorting: true },
    { id: 'published', label: 'Published', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { id: 'actions', label: 'Actions', disableSorting: true }
  ];

  const getCategory = async () => {
    try {
      const res = await helper.get(`${apiPath.get_blog_categories}`, session);
      if (res.status === 200) {
        if (res.data.status === 200) {
          setCategories(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBlogsTableData();
  }, [categoryId, blogStatus, orderBy]);

  useEffect(() => {
    getCategory();
  }, []);

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.title.toLowerCase().includes(target.value));
      }
    });
  };

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    records,
    headCell,
    filterFn,
    searchKeyword
  );

  const handleBlogDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_blog}/${item.id}`, session);
      if (res.status === 200) {
        getBlogsTableData();
        toast.success(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Page title={'Blogs List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Blogs
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Blogs</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" style={{ justifyContent: 'space-between' }}>
              <Controls.Input
                label="Search Blogs"
                style={{ width: '30%' }}
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />
              <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                <Select
                  style={{ marginLeft: '10px' }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Category"
                  value={categoryId}
                  onChange={(e) => {
                    setCategoryId(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {categories.length > 0 &&
                    categories.map((e, key) => {
                      return <MenuItem value={e.id}>{e.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: 100, marginTop: '0%' }}>
                <InputLabel id="demo-simple-select-label">Blog Status</InputLabel>
                <Select
                  style={{ marginLeft: '10px' }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Blog Status"
                  value={blogStatus}
                  onChange={(e) => {
                    setBlogStatus(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="1">Published</MenuItem>
                  <MenuItem value="0">Drafted</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
                <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                <Select
                  style={{ marginLeft: '10px' }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Blog Status"
                  value={orderBy}
                  onChange={(e) => {
                    setOrderBY(e.target.value);
                  }}
                >
                  <MenuItem value="ASC">Oldest - Newest</MenuItem>
                  <MenuItem value="DESC">Newest - Oldest</MenuItem>
                </Select>
              </FormControl>

              <Controls.Button
                text="Add New Blog"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  navigate('/blogs/add');
                }}
              />
            </Stack>
          </Toolbar>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.category_id.name}</TableCell>
                  <TableCell>{item.blogLikes}</TableCell>
                  <TableCell>{item.sub_category_id?.name}</TableCell>
                  <TableCell>
                    {item.published ? (
                      <Button variant="contained">Published</Button>
                    ) : (
                      <Button variant="outlined" color="error">
                        Draft
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        navigate(`/blogs/edit/${item.id}`);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    {/* <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        handleBlogView(item);
                      }}
                    >
                      <RemoveRedEyeIcon fontSize="small" />
                    </Controls.ActionButton> */}

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleBlogDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
    </>
  );
}
